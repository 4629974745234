import React from 'react';
import '../styles/brand.css';
import BrandImg from '../images/shouting-man.png';
import {IoPeopleOutline} from 'react-icons/io5';
import {GiCheckMark} from 'react-icons/gi';
import {AiOutlineGlobal} from 'react-icons/ai';
import {SlTarget} from 'react-icons/sl';

function Brand() {
  return (
    <div>
        <div className='container-fluid g-0 bg-light pt-5'>
            <div className='container'>
                <div className='row'>
                    <div className='d-none d-lg-block col-md-6'>
                        <img className='brand-img' src={BrandImg} alt='img'></img>
                    </div>
                    <div className='col-12 col-lg-6 brand-title'>
                        <div>    
                            <h2>Markanıza Değer Katalım</h2>
                            <p>Markanızı daha büyük ve daha parlak bir geleceğe taşımak için buradayız. Deneyimli ekibimiz, sizlere danışmanlık sağlayarak markanızın potansiyelini en üst düzeye çıkarır ve rekabet avantajı sağlar.</p>
                            <div className='row'>
                                <div className='col-12 col-md-6 d-flex align-items-center justify-content-center flex-column'>
                                    <div className='brand-cart'>
                                        <span><IoPeopleOutline/></span>
                                        <p>Uzman Ekip</p>
                                    </div>
                                    <div className='brand-cart'>
                                        <span><AiOutlineGlobal/></span>
                                        <p>Profesyonel</p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 d-flex align-items-center justify-content-center flex-column'>
                                    <div className='brand-cart'>
                                        <span><GiCheckMark/></span>
                                        <p>Başarılı</p>
                                    </div>
                                    <div className='brand-cart'>
                                        <span><SlTarget/></span>
                                        <p>Hedef Odaklı</p>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Brand