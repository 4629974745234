import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Achievements extends Component {	
	render() {
		const options = {
            
			animationEnabled: true,
            backgroundColor: "#000",
			exportEnabled: false,
			theme: "dark2", // "light1", "dark1", "dark2"
			title:{
				text: "Toplam Başarı Oranlarımız"
			},
			data: [{
				type: "pie",
				indexLabel: "{label}: {y}%",		
				startAngle: -90,
				dataPoints: [
					{ y: 100, label: "KOSGEB" },
					{ y: 75, label: "TÜBİTAK" },
					{ y: 75, label: "Teknofest" }	
				]
			}]
		}
		
		return (
		<div>
			<CanvasJSChart options = {options} containerProps={{ width: '100%', height: '400px' }}/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}