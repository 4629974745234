import React from 'react'
import '../styles/argeservice.css';
import arge from '../images/arge2.svg';

function ArGeService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={arge} alt='arge'></img></div>
        <h1 className='text-bold'>Ar-Ge Danışmanlığı</h1>
        <h5>Kobimer Ar-Ge Danışmanlık: İnovasyon ve Verimliliği Birleştirme Yolculuğunuzda Yanınızda</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Teknolojinin hızlı evrimiyle başa çıkarken, Ar-Ge ve inovasyon projelerinizi yönetmek, başarı için kritik bir adımdır. Kobimer Ar-Ge Danışmanlık olarak, bu zorlu süreçte sizlere rehberlik etmek ve işletmenizin potansiyelini en üst düzeye çıkarmak için buradayız.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Farkındalık ve Strateji Oluşturma:</span><span> Ar-Ge biriminizde çalışan ekipler arası uyumu sağlamak ve doğru stratejiyi oluşturmak adına detaylı planlamalar yapıyoruz.</span></li>
          <li><span className='text-bold'>SWOT Analizi ve Stratejik Planlama:</span><span> Geçmiş projelerin analizi, güçlü ve zayıf yönlerin belirlenmesi ve stratejik planların oluşturulması.</span></li>
          <li><span className='text-bold'>Portföy Yönetimi ve Revizyon:</span><span> Proje fikir akışını düzenleme, analiz metodolojilerini iyileştirme ve proje seçim süreçlerini gözden geçirme.</span></li>
          <li><span className='text-bold'>Operasyonel Mükemmellik:</span><span> Yönetim verimliliğini artırma, görev tanımları ve süreç tanımlamalarını iyileştirme, kaynak yönetimi ve Ar-Ge operasyonlarını optimize etme çalışmaları.</span></li>
          <li><span className='text-bold'>Proje Yönetimi İyileştirmeleri:</span><span> PMP yetkinliği kazandırma, proje yönetim sistematiği oluşturma ve mevcut yazılımları verimli kullanma.</span></li>
        </ul>
        <p className='padding-y'>Kobimer Ar-Ge Danışmanlık olarak, her adımda yanınızda olmak ve işletmenizin potansiyelini keşfetmenize rehberlik etmek için buradayız. İnovasyonun gücünü keşfedin, beraberinde getirdiği başarıya odaklanın.</p>
      </div>
    </div>
  )
}

export default ArGeService