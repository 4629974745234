import React from 'react'
import '../styles/argeservice.css';
import tkdk from '../images/tkdk.svg';

function TkdkService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={tkdk} alt='tkdk'></img></div>
        <h1 className='text-bold'>TKDK Danışmanlığı</h1>
        <h5>TKDK Danışmanlığı: Tarımsal ve Kırsal Kalkınmanın Anahtarı</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Tarımsal ve kırsal alanlarda sürdürülebilir kalkınma hedeflerine ulaşmak, işletmenizin ve bölgenizin potansiyelini en üst düzeye çıkarmak istiyorsanız, Türkiye Kırsal Kalkınmayı Destekleme Kurumu (TKDK) danışmanlığı doğru adrestir.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Hibelerden En İyi Şekilde Faydalanma:</span><span> TKDK'nın sağladığı çeşitli destek ve hibe programlarından en uygun olanları belirleyerek işletmenizin ve projelerinizin finansmanını güçlendiriyoruz.</span></li>
          <li><span className='text-bold'>Proje Geliştirme ve Yönetim:</span><span> TKDK projelerinizin başarıya ulaşması için gerekli olan stratejik planlama, bütçeleme ve proje yönetimi süreçlerini profesyonelce yönetiyoruz.</span></li>
          <li><span className='text-bold'>Tarımsal ve Kırsal Yatırım Danışmanlığı:</span><span> Tarım ve kırsal alanda gerçekleştirmeyi planladığınız yatırımları analiz ediyor, uygun stratejileri belirliyor ve başvuru süreçlerinizi etkin bir şekilde yönetiyoruz.</span></li>
          <li><span className='text-bold'>İş Planı ve Mali Analiz:</span><span> İşletmenizin potansiyelini en iyi şekilde değerlendirmek için detaylı iş planları ve mali analizler yapıyoruz. Böylece sürdürülebilir bir büyüme stratejisi oluşturmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Eğitim ve Kapasite Geliştirme:</span><span> İşletme sahipleri ve çalışanları için özel eğitim programları düzenliyoruz. Tarımsal ve kırsal konularda uzmanlaşmanızı sağlayarak rekabet avantajı elde etmenize yardımcı oluyoruz.</span></li>
        </ul>
        <p className='padding-y'>TKDK danışmanlığı ile tarımsal ve kırsal kalkınma alanındaki projelerinizi daha etkili bir şekilde yönetin, işletmenizi sürdürülebilir bir başarıya taşıyın. Profesyonel ekibimizle birlikte, TKDK kaynaklarından en iyi şekilde faydalanmanız için yanınızdayız.</p>
      </div>
      <div className='container text-center service-wrapper'>
        <h5>Sürdürülebilir Tarım Uygulamaları Danışmanlığı: Topraklarınıza Değer Katın, Geleceği Şekillendirin!</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Tarım sektörü, sadece bugünün değil, gelecek nesillerin de ihtiyaçlarını karşılamak adına sürdürülebilir uygulamalara yönelmek zorundadır. Sürdürülebilir tarım uygulamaları danışmanlığı hizmetlerimizle, işletmenizin tarımsal faaliyetlerini çevre dostu, ekonomik ve toplumsal açıdan sürdürülebilir bir şekilde optimize etmenize yardımcı oluyoruz.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Toprak Analizi ve Verimlilik Artırma:</span><span> Topraklarınızın durumu hakkında detaylı analizler yaparak, doğru gübreleme, sulama ve ekim stratejileri geliştiriyoruz. Bu sayede toprak verimliliğinizi artırıyor ve sürdürülebilir tarımın temelini oluşturuyoruz.</span></li>
          <li><span className='text-bold'>Su Yönetimi ve Verimlilik:</span><span> Sulama sistemlerinizi değerlendiriyor, su kullanımını optimize ederek tasarruf sağlıyor ve çevresel etkileri en aza indiriyoruz. Su kaynaklarını sürdürülebilir bir şekilde kullanarak verimliliği artırıyoruz.</span></li>
          <li><span className='text-bold'>Organik Tarım Geçiş Süreci:</span><span> Organik tarıma geçiş konusunda size rehberlik ediyor, sertifikasyon süreçlerini yönetiyor ve organik üretimdeki potansiyelinizi maksimuma çıkarmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Biyoçeşitliliği Destekleme:</span><span> Ekosistem dengesini korumak adına, biyoçeşitliliği artırmak ve tarım alanlarınızda doğal dengeyi desteklemek için stratejiler geliştiriyoruz.</span></li>
          <li><span className='text-bold'>Enerji Verimliliği ve Karbon Ayak İzi Azaltma:</span><span> Tarım işletmenizin enerji kullanımını değerlendiriyor, enerji verimliliği önlemleri alarak maliyetlerinizi düşürüyor ve karbon ayak izinizi azaltmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Eğitim ve Kapasite Geliştirme:</span><span> Tarım ekibinizi sürdürülebilir tarım konusunda bilinçlendiriyor, güncel uygulamaları öğretiyor ve sürekli eğitim programları ile kapasitelerini geliştirmelerine destek oluyoruz.</span></li>
        </ul>
        <p className='padding-y'>Sürdürülebilir tarım uygulamaları danışmanlığı hizmetlerimizle, işletmenizin çevresel etkilerini azaltırken, verimliliği artırmanın yanı sıra, tüketici taleplerine uygun ve geleceğe yönelik bir tarım modeli oluşturmanıza yardımcı oluyoruz. Gelecek nesillere sağlıklı topraklar, temiz su kaynakları ve sürdürülebilir bir tarım mirası bırakmak için bizimle birlikte adım atın!</p>
      </div>
    </div>
  )
}

export default TkdkService