import React from 'react'
import '../styles/contact.css';
import { GoMail } from 'react-icons/go';
import { BsTelephone } from 'react-icons/bs';
import { SlLocationPin } from 'react-icons/sl';


function Contact() {
  return (
    <div className='container-fluid bg-black'>
        <div className='container text-white text-center min-vh'>
            <h1 className='contact-title'>Bize Nasıl Ulaşabilirsiniz?</h1>
            <div className='border-bottom'></div>
            <div className='info-row'>
                <div className='info_icon'><GoMail /></div>
                <div className='info_text'><span>E-Posta: info@kobimer.net</span></div>
            </div>
            <div className='info-row'>
                <div className='info_icon'><BsTelephone /></div>
                <div className='info_text'><span>Telefon: +(90) 532 260 42 00</span></div>
            </div>
            <div className='info-row'>
                <div className='info_icon'><SlLocationPin /></div>
                <div className='info_text'><span>Adres: Nevşehir Hacı Bektaş Veli Üniversitesi<br/>Kapadokya Teknopark No: 13, Merkez/Nevşehir</span></div>
            </div>
        </div>
    </div>
  )
}

export default Contact