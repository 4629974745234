import React from 'react'
import '../styles/argeservice.css';
import engineer from '../images/engineer.svg';

function EngineeringService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={engineer} alt='engineer'></img></div>
        <h1 className='text-bold'>Mühendislik Danışmanlığı</h1>
        <h5>Mühendislik Danışmanlığı: İnovasyonun ve Mükemmelliğin Anahtarı!</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Teknolojinin hızla evrim geçirdiği günümüzde, mühendislik alanında doğru strateji ve çözümlerle adımınızı öne çıkarmak zorunlu hale gelmiştir. İşte tam da bu noktada, mühendislik danışmanlığı hizmetlerimiz devreye giriyor.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Proje Yönetimi ve Stratejik Planlama:</span><span> Mühendislik projelerinizin her aşamasını yönetmek, kaynakları doğru kullanmak ve hedeflere ulaşmak adına stratejik planlar oluşturuyoruz. Proje yönetiminde uzman kadromuzla size rehberlik ediyoruz.</span></li>
          <li><span className='text-bold'>İnovasyon ve AR-GE:</span><span> İnovasyonun kalbinde yer almak için AR-GE stratejileri geliştiriyor, yeni teknolojileri takip ediyor ve uygulamaya geçirerek işletmenizin rekabet avantajını artırmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Verimlilik Analizi ve İyileştirme:</span><span> Mevcut süreçlerinizi detaylı bir şekilde analiz ediyor, verimlilik raporları oluşturuyor ve uygun mühendislik çözümleri ile süreçlerinizi iyileştirerek maliyetleri düşürmenize yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Enerji ve Çevre Yönetimi:</span><span> Sürdürülebilirlik odaklı çözümlerle enerji verimliliği sağlıyor, çevresel etkileri azaltıyor ve işletmenizi çevre dostu bir şekilde yönetmenize destek oluyoruz.</span></li>
          <li><span className='text-bold'>Malzeme ve Kaynak Yönetimi:</span><span> Malzeme tüketimini optimize ediyor, kaynakları etkili bir şekilde kullanarak israfı en aza indiriyoruz. Bu sayede maliyetleri düşürüyor ve sürdürülebilir bir üretim modeli oluşturmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Risk Analizi ve Güvenlik Çözümleri:</span><span> İşletmenizin karşılaşabileceği riskleri belirliyor, güvenlik protokollerini gözden geçiriyor ve uygun çözümlerle iş sürekliliğinizi sağlıyoruz.</span></li>
          <li><span className='text-bold'>Teknoloji Entegrasyonu:</span><span> En son teknolojiyi iş süreçlerinize entegre ederek dijital dönüşümü destekliyor, işletmenizin rekabet avantajını artırıyoruz.</span></li>
        </ul>
        <p className='padding-y'>Mühendislik danışmanlığı hizmetlerimizle, teknik bilgi birikimimiz ve deneyimimizle işletmenizi geleceğe taşımanızı sağlıyor, mühendislik projelerinizde mükemmellik ve inovasyonu bir araya getiriyoruz. Geleceğe yönelik güçlü adımlar atmak için bizimle iletişime geçin!</p>
      </div>
    </div>
  )
}

export default EngineeringService