import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/news.css';
import kobi from '../images/kobi_logo.png';
import tekno from '../images/teknofest_logo.jpg';
import tubitaklogo from '../images/tubitak-personel-alimini-duyurdu-xgFh_cover.webp';
import projepazari from '../images/proje_pazari_logo.jpg';
import ksu from '../images/ksu_logo.png';
import tubitak from '../images/2020.07.08-TUBITAK.jpg';
import news1 from '../images/news1.jpg';
import news2 from '../images/news2.jpg';
import news3 from '../images/news3.jpg';
import news4 from '../images/news4.jpg';
import news5 from '../images/news5.jpg';

function KosgebProjects() {
  return (
    <div className='container-fluid bg-black text-white'>
        <div className='container'>
            <div className='title'>
                <h1 className='project-title'>Bizden Haberler</h1>                
            </div>
            {/* <-- Project Seperator --> */}
            <Link className='news-link' to="https://www.kosgeb.gov.tr/site/tr/genel/detay/8859/savunma-sanayine-insansiz-kesif-robotu" target='_blank'>
              <div className='kosgeb-project-wrapper'>
                <div className='row kosgeb-project-box'>
                  <div className='col-lg-4 kosgeb-project-img-box'>
                    <img className='kosgeb-project-img' src={news4} alt='news'></img>
                  </div>
                  <div className='col-lg-8 kosgeb-project-text-box'>
                    <div className='kosgeb-project-text-box-title'><h3>Savunma Sanayine İnsansız Keşif Robotu</h3></div>
                    <div>
                      <p>Kapadokya Teknopark'ta 2021 yılında firmasını kuran Eray Sakarya, tasarladığı havada ve karada hareket etme yeteneğine sahip keşif robotunu Türk Silahlı Kuvvetleri'nin envanterine kazandırmayı hedefliyor...</p>
                    </div>                  
                  </div>                  
                </div>    
                <div className='news-logo-box'><img className='news-logo' src={kobi} alt='news_logo'></img></div>          
                <div className='news-date-box'><span>03.07.2023</span></div>     
              </div>
            </Link>            
            {/* <-- Project Seperator --> */}                                  
            {/* <-- Project Seperator --> */}
            <Link className='news-link' to="https://mmf.ksu.edu.tr/default.aspx?DId=72558" target='_blank'>
              <div className='kosgeb-project-wrapper'>
                <div className='row kosgeb-project-box'>
                  <div className='col-lg-4 kosgeb-project-img-box'>
                    <img className='kosgeb-project-img' src={tubitak} alt='news'></img>
                  </div>
                  <div className='col-lg-8 kosgeb-project-text-box'>
                    <div className='kosgeb-project-text-box-title'><h3>3 Boyutlu Tünel Haritalama Robotu</h3></div>
                    <div>
                      <p>Elektrik ve Elektronik mühendisliği öğrencimiz Eray SAKARYA'nın hazırladığı 3 boyutlu Tünel Haritalama Robotu isimli proje TÜBİTAK-2209-A kapsamında desteklenmeye uygun bulunmuştur...</p>
                    </div>                  
                  </div>                  
                </div>    
                <div className='news-logo-box'><img className='news-logo' src={ksu} alt='news_logo'></img></div>          
                <div className='news-date-box'><span>28.01.2023</span></div>     
              </div>
            </Link>            
            {/* <-- Project Seperator --> */}                                  
            {/* <-- Project Seperator --> */}
            <Link className='news-link' to="https://www.linkedin.com/posts/mutlu-%C3%B6%C4%9F%C3%BCtgen-6197942b_indexteknoloji-kobimer-activity-7130909881548591106-jHQD?utm_source=share&utm_medium=member_desktop" target='_blank'>
              <div className='kosgeb-project-wrapper'>
                <div className='row kosgeb-project-box'>
                  <div className='col-lg-4 kosgeb-project-img-box'>
                    <img className='kosgeb-project-img' src={news5} alt='news'></img>
                  </div>
                  <div className='col-lg-8 kosgeb-project-text-box'>
                    <div className='kosgeb-project-text-box-title'><h3>TECH-AT At Simülatörü</h3></div>
                    <div>
                      <p>KOSGEB Ar-Ge ve İnovasyon Destek Programı kapsamında desteklenen, patentini aldığımız TECH-AT projemizin izleme dönemi başarılı bir şeklide tamamlanmıştır...</p>
                    </div>                  
                  </div>                  
                </div>    
                <div className='news-logo-box'><img className='news-logo' src={kobi} alt='news_logo'></img></div>          
                <div className='news-date-box'><span>21.04.2022</span></div>     
              </div>
            </Link>            
            {/* <-- Project Seperator --> */}                                  
            {/* <-- Project Seperator --> */}
            <Link className='news-link' to="https://www.linkedin.com/feed/update/urn:li:activity:6885115704295596032/" target='_blank'>
              <div className='kosgeb-project-wrapper'>
                <div className='row kosgeb-project-box'>
                  <div className='col-lg-4 kosgeb-project-img-box'>
                    <img className='kosgeb-project-img' src={news3} alt='news'></img>
                  </div>
                  <div className='col-lg-8 kosgeb-project-text-box'>
                    <div className='kosgeb-project-text-box-title'><h3>TÜBİTAK 1512 Girişimcilik Destek Programı (BiGG)</h3></div>
                    <div>
                      <p>TÜBİTAK Başkanı Prof. Dr. Hasan Mandal ve Nevşehir Hacı Bektaş Veli Üniversitesi Rektörü Prof. Dr. Semih Aktekin'e ziyaretlerinden dolayı teşekkür ederiz. ...</p>
                    </div>                  
                  </div>                  
                </div>    
                <div className='news-logo-box'><img className='news-logo' src={tubitaklogo} alt='news_logo'></img></div>  
                <div className='news-date-box'><span>02.03.2022</span></div>        
              </div>
            </Link>            
            {/* <-- Project Seperator --> */}                                  
            {/* <-- Project Seperator --> */}
            <Link className='news-link' to="https://www.aa.com.tr/tr/bilim-teknoloji/askeri-operasyonlara-katki-saglayacak-cihazla-birincilik-elde-ettiler/1988478" target='_blank'>
              <div className='kosgeb-project-wrapper'>
                <div className='row kosgeb-project-box'>
                  <div className='col-lg-4 kosgeb-project-img-box'>
                    <img className='kosgeb-project-img' src={news2} alt='news'></img>
                  </div>
                  <div className='col-lg-8 kosgeb-project-text-box'>
                    <div className='kosgeb-project-text-box-title'><h3>Askeri operasyonlara katkı sağlayacak cihazla birincilik elde ettiler</h3></div>
                    <div>
                      <p>Tehlikeli mağaraların içlerinin tespiti için geliştirdikleri "Pratik5000" ile TEKNOFEST 2020'de birinci olan KSÜ Elektrik ve Elektronik Mühendisiliği Bölümü öğrencileri başarılarını Rektör Prof. Dr. Niyazi Can ile paylaştı....</p>
                    </div>                  
                  </div>                  
                </div>    
                <div className='news-logo-box'><img className='news-logo' src={tekno} alt='news_logo'></img></div> 
                <div className='news-date-box'><span>28.09.2020</span></div>         
              </div>
            </Link>            
            {/* <-- Project Seperator --> */}                                  
            {/* <-- Project Seperator --> */}
            <Link className='news-link' to="https://www.star.com.tr/teknoloji/teror-yuvalarinda-kendini-feda-edecek-helikopter-haber-1450289/" target='_blank'>
              <div className='kosgeb-project-wrapper'>
                <div className='row kosgeb-project-box'>
                  <div className='col-lg-4 kosgeb-project-img-box'>
                    <img className='kosgeb-project-img' src={news1} alt='news'></img>
                  </div>
                  <div className='col-lg-8 kosgeb-project-text-box'>
                    <div className='kosgeb-project-text-box-title'><h3>Mühimmat taşıyarak kendini patlatabilen 'Fedai-B' adını verdikleri helikopteri tasarladılar</h3></div>
                    <div>
                      <p>Kahramanmaraş Sütçü İmam Üniversitesi (KSÜ) öğrencileri, başta terörist hedefler olmak üzere askeri alanda kullanılabilecek 'insansız helikopter prototipi' geliştirdi....</p>
                    </div>                  
                  </div>                  
                </div>    
                <div className='news-logo-box'><img className='news-logo' src={projepazari} alt='news_logo'></img></div>      
                <div className='news-date-box'><span>30.04.2019</span></div>    
              </div>
            </Link>            
            {/* <-- Project Seperator --> */}                                  
        </div>
    </div>
  )
}

export default KosgebProjects