import React from 'react'
import '../styles/argeservice.css';
import kobi from '../images/kobi.svg';

function KobiService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={kobi} alt='kobi'></img></div>
        <h1 className='text-bold'>KOBİ Danışmanlığı</h1>
        <h5>KOBİ Danışmanlığı: İşinizi Büyütmenin ve Geliştirmenin Anahtarı</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Küçük ve Orta Büyüklükteki İşletmeler (KOBİ'ler), ekonominin can damarlarıdır. Bu nedenle, işletmenizin potansiyelini en üst düzeye çıkarmak ve rekabet avantajınızı artırmak için KOBİ danışmanlığı hizmetlerimizle yanınızdayız.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Stratejik Planlama:</span><span> İlk adım olarak, işletmenizin mevcut durumunu değerlendiriyor ve uzun vadeli hedeflerinizi belirliyoruz. Stratejik bir plan oluşturarak, rekabet avantajınızı artırmanıza ve büyüme potansiyelinizi en üst düzeye çıkarmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Finansal Yönetim:</span><span> Mali tablolarınızı analiz ederek, mali verimliliğinizi artırmak ve nakit akışınızı optimize etmek için stratejiler geliştiriyoruz. Ayrıca, uygun finansman kaynaklarını bulmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>İnsan Kaynakları Yönetimi:</span><span> Personel yönetimi, işletmenizin başarısında kritik bir rol oynar. İnsan kaynakları stratejileri geliştirerek, personel performansını artırmak, eğitim programları oluşturmak ve etkili bir iş gücü yönetimi sağlamak için size rehberlik ediyoruz.</span></li>
          <li><span className='text-bold'>Pazarlama ve Satış Stratejileri:</span><span> Ürün veya hizmetlerinizi doğru bir şekilde pazarlamak ve satmak, işletmenizin büyüme sürecinde önemli bir faktördür. Pazarlama stratejileri oluşturarak, hedef kitlenize daha etkili bir şekilde ulaşmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Teknoloji ve İnovasyon:</span><span> İnovasyon, işletmelerin rekabet avantajını sürdürmesi için önemlidir. Teknolojik altyapınızı geliştirmenize ve iş süreçlerinizi optimize etmenize yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Risk Yönetimi:</span><span> İş dünyasında karşılaşılan riskler, doğru bir şekilde yönetilmediği takdirde büyümeyi engelleyebilir. İşletmenizin risk profilini analiz ediyor ve etkili risk yönetimi stratejileri oluşturmanıza destek oluyoruz.</span></li>
          <li><span className='text-bold'>Sürdürülebilirlik Stratejileri:</span><span> Çevresel ve toplumsal sorumlulukları içeren sürdürülebilirlik stratejileri geliştirerek, işletmenizi gelecek nesillere taşımanıza katkı sağlıyoruz.</span></li>
        </ul>
        <p className='padding-y'>KOBİ danışmanlığı hizmetlerimizle işletmenizin potansiyelini maksimize edin, rekabet avantajınızı artırın ve sürdürülebilir bir büyüme sağlayın. Büyük hedeflere küçük adımlarla ulaşmak için biz buradayız.</p>
      </div>
    </div>
  )
}

export default KobiService