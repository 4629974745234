import React from 'react';
import '../styles/services.css';
import { MdComputer } from "react-icons/md";
import { BsGear, BsCalendar3 } from "react-icons/bs";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";

function Services() {
  return (
    <div>
        <div className='container-fluid g-0'>
          <div className='service-container'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-xxl-6 service-title'>
                  <h1>Profesyonel Hizmetlerimiz</h1>
                  <p>Kobimer olarak müşterilerimize iş dünyasındaki en büyük zorluklarla başa çıkmalarına yardımcı olmak için özel olarak tasarlanmış bir dizi profesyonel danışmanlık hizmeti sunmaktadır. Müşterilerimizin başarısı, bizim başarımızdır ve bu nedenle müşteri memnuniyeti, çalışmalarımızın merkezinde yer almaktadır. Size özel çözümler sunarak, iş ihtiyaçlarınıza uygun stratejiler geliştiriyoruz.</p>
                </div>
                <div className='col-12 col-xxl-6'>
                  <div className='row'>
                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center flex-column'>
                      <div className='service-cart bg-yellow'>
                        <div className='service-icon'><MdComputer /></div>
                        <h4>Ar-Ge Danışmanlık</h4>
                        <p>Yenilikçi projelerinizi yönlendirir ve rekabet avantajı sağlarız. İnovasyonunuzu desteklemek için buradayız.</p>
                      </div>
                      <div className='service-cart bg-white'>
                        <div className='service-icon'><HiOutlineBuildingStorefront /></div>
                        <h4>TKDK Danışmanlık</h4>
                        <p>TKDK danışmanlığı, tarım ve kırsal kalkınma projelerini destekler, hibe ve teşvikler hakkında rehberlik sağlamak için buradayız.</p>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center flex-column'>
                    <div className='service-cart bg-white'>
                        <div className='service-icon'><BsGear /></div>
                        <h4>Kosgeb Danışmanlık</h4>
                        <p>KOSGEB danışmanlığı, işletmeleri destekler, hibe ve teşvik programları hakkında bilgi verir. İşinizi büyütmek için yanınızdayız.</p>
                      </div>
                      <div className='service-cart bg-white'>
                        <div className='service-icon'><BsCalendar3 /></div>
                        <h4>Yatırım Danışmanlık</h4>
                        <p>Yatırımlarınızdan en kısa zamanda sağlıklı geri dönüş alabilmeniz ve hedeflenen noktaya ulaşabilmeniz için yanınızdayız.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Services