import React from 'react'
import '../styles/hero.css';
import hero from '../images/hero2.jpg';
import logo from '../images/Kobimer_Logo_1_Hero.png'

function Hero() {
  return (
    <div className="container-fluid bg-black">
        <div className='container hero'>
            <div className='hero-text'>
                <img src={logo} alt='logo'></img>
            </div>
            <div className='hero-img'>
                <img src={hero} alt='hero'></img>
            </div>
        </div>
    </div>
  )
}

export default Hero