import React from 'react'
import '../styles/argeservice.css';
import kosgeb from '../images/kosgeb.svg';

function KosgebService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={kosgeb} alt='arge'></img></div>
        <h1 className='text-bold'>KOSGEB Danışmanlığı</h1>
        <h5>KOSGEB Danışmanlığı: İşinizi Büyütmenin ve Geliştirmenin Anahtarı</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>İşletmenizin potansiyelini en üst düzeye çıkarmak, büyütmek ve rekabet avantajı elde etmek için doğru adımları atmaya hazır mısınız? İşte tam da bu noktada KOSGEB danışmanlığı devreye giriyor. KOSGEB'in sunduğu destek ve hibelerden en etkili şekilde faydalanmak, iş süreçlerinizi iyileştirmek ve sürdürülebilir büyüme sağlamak için profesyonel bir danışmanlık hizmeti almak işletmeniz için bir dönüm noktası olabilir.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Hibe ve Destek Programları Analizi:</span><span> KOSGEB'in sağladığı çeşitli hibe ve destek programlarını detaylı bir şekilde analiz ediyoruz. İşletmenizin ihtiyaçlarına en uygun olanları belirliyor ve başvuru süreçlerinizi yönetiyoruz.</span></li>
          <li><span className='text-bold'>Stratejik Planlama ve Proje Yönetimi:</span><span> İşletmenizin hedeflerine ulaşması için stratejik bir plan oluşturuyoruz. Proje yönetimi süreçlerini etkin bir şekilde yöneterek başarıya odaklanmanıza yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Finansal Danışmanlık:</span><span> Maliyet analizi, bütçe planlama ve finansal stratejilerle ilgili uzman danışmanlık sağlıyoruz. İşletmenizin mali sağlığını güçlendirmenize destek oluyoruz.</span></li>
          <li><span className='text-bold'>İş Süreçleri İyileştirme:</span><span> Verimliliği artırmak, maliyetleri düşürmek ve rekabet avantajı elde etmek için iş süreçlerinizi gözden geçiriyoruz. İşletmenizin daha hızlı, daha etkili ve daha esnek olmasını sağlıyoruz.</span></li>
          <li><span className='text-bold'>Eğitim ve Gelişim Programları:</span><span> İşletme sahipleri ve çalışanları için özel eğitim ve gelişim programları tasarlıyoruz. İnovasyon, pazarlama, liderlik ve diğer alanlarda uzmanlaşmanızı sağlıyoruz.</span></li>
        </ul>
        <p className='padding-y'>KOSGEB danışmanlığı ile işletmenizin büyüme potansiyelini keşfedin, sürdürülebilir başarıya bir adım daha yaklaşın. Profesyonel ekibimizle birlikte, işletmenizin hedeflerine ulaşması için gereken adımları atalım.</p>
      </div>
    </div>
  )
}

export default KosgebService