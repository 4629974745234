import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Achievements extends Component {	
	render() {
		const options = {
				animationEnabled: true,
                backgroundColor: "#000",
				theme: "dark2",
				title:{
					text: "Yıllar İçinde Başarılı Proje Sayıları"
				},
				axisY : {
					title: "Başarılı Projeler"
				},
				toolTip: {
					shared: true
				},
				data: [{
					type: "spline",
					name: "KOSGEB",
					showInLegend: true,
					dataPoints: [
						{ y: 1, label: "2020" },
						{ y: 0, label: "2021" },
						{ y: 0, label: "2022" },
						{ y: 1, label: "2023" }						
					]
				},
				{
					type: "spline",
					name: "TÜBİTAK",
					showInLegend: true,
					dataPoints: [
						{ y: 1, label: "2019" },
						{ y: 1, label: "2020" },
						{ y: 0, label: "2021" },
						{ y: 1, label: "2022" },
						{ y: 2, label: "2023" }						
					]
				}]
		}
		
		return (
		<div>
			<CanvasJSChart options = {options} containerProps={{ width: '100%', height: '400px' }}/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}