import React from 'react'
import '../styles/argeservice.css';
import sgk from '../images/sgk.svg';

function SgkService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={sgk} alt='sgk'></img></div>
        <h1 className='text-bold'>SGK Teşvik Danışmanlığı</h1>
        <h5>SGK Teşvik Danışmanlığı: İşletmenizi Güçlendirin, Teşvikleri Kaçırmayın!</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>İş dünyasında rekabetin her geçen gün arttığı bu dönemde, işletmelerin SGK teşviklerinden en iyi şekilde faydalanması, sürdürülebilir büyüme ve maliyet avantajı elde etmelerini sağlar. SGK teşvik danışmanlığı hizmetlerimizle işletmenizin bu avantajlardan tam anlamıyla yararlanmasını sağlıyoruz.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Teşvik Analizi ve Değerlendirme:</span><span> İşletmenizin yapısına uygun SGK teşviklerini belirlemek için detaylı bir analiz yapıyor ve mevcut durumunuza en uygun teşvikleri değerlendiriyoruz.</span></li>
          <li><span className='text-bold'>Başvuru Süreçleri ve Takibi:</span><span> Teşvik başvurularının karmaşık süreçleriyle uğraşmak zorunda kalmadan, uzman ekibimiz aracılığıyla başvurularınızı hazırlıyor ve süreci takip ediyoruz.</span></li>
          <li><span className='text-bold'>Teşvikten Yararlanma Optimizasyonu:</span><span> İşletmenizin özel ihtiyaçlarına uygun olarak, teşviklerden en yüksek düzeyde faydalanmanızı sağlamak üzere stratejiler geliştiriyor ve uyguluyoruz.</span></li>
          <li><span className='text-bold'>Hukuki Danışmanlık:</span><span> SGK teşvikleriyle ilgili hukuki süreçleri yönetmek, başvuruları hazırlamak ve işletmenizin hak ettiği teşviklere ulaşmasını sağlamak adına hukuki danışmanlık hizmeti sunuyoruz.</span></li>
          <li><span className='text-bold'>Teşvik İzleme ve Raporlama:</span><span> Aldığınız teşviklerin sürekli izlenmesi ve düzenli raporlanmasıyla, işletmenizin teşviklerden maksimum düzeyde faydalanmasını sağlıyoruz.</span></li>
        </ul>
        <p className='padding-y'>SGK teşvik danışmanlığı hizmetlerimizle, işletmenizin sosyal güvenlik maliyetlerini optimize etmenin yanı sıra, nitelikli işgücü sağlamak ve yatırımlarınızı daha verimli hale getirmek için gerekli adımları atmanıza yardımcı oluyoruz. İşletmenizi güçlendirmek ve rekabet avantajı sağlamak için SGK teşviklerinden en iyi şekilde yararlanmanız için yanınızdayız.</p>
      </div>
    </div>
  )
}

export default SgkService