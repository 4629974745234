import React from 'react';
import '../styles/abouthero.css';
import img from '../images/aboutus.jpg';

function AboutHero() {
  return (
    <div>
        <div className="container-fluid about">
            Hakkımızda
        </div>
        <div className="container-fluid py-5">
            <div className="row">
                <div className="col-xl-6 img-flex">
                    <img className='about-img' src={img} alt="img" ></img>
                </div>
                <div className="col-xl-6 about-text">
                    <h2>Biz Kimiz<span>?</span></h2>
                    <p className="p-5">Kobimer Ar-Ge Danışmanlık, müşterilere stratejik Ar-Ge çözümleri sunan öncü bir firmadır. Deneyimli ekip, teknoloji transferi ve inovasyon konularında uzmandır. Şeffaf ve güvene dayalı yaklaşımıyla her projede değer yaratır. Kobimer, müşteri memnuniyetini öncelik olarak benimseyerek, iş dünyasındaki değişimde liderlik eder ve müşterilere rekabet avantajı kazandırır. Yenilikçi çözümlerle geleceğe yön veren Kobimer Ar-Ge Danışmanlık, güvenilir bir iş ortağıdır.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutHero