import { useRef } from 'react';
import {FaBars, FaTimes} from 'react-icons/fa';
import '../styles/navbar.css';
import Logo from '../images/Kobimer_Logo_1.png';
import { Link } from 'react-router-dom';

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive-nav");
  }


  return (
      <header>
        <img src={Logo} alt='Logo img'></img>
        <nav ref={navRef}>
          <Link className='link' to="/" onClick={showNavbar}>Anasayfa</Link>
          <Link className='link' to="/about" onClick={showNavbar}>Hakkımızda</Link>          
          <Link className='link' to="/services" onClick={showNavbar}>Hizmetlerimiz</Link>         
          <Link className='link' to="/consultants" onClick={showNavbar}>Danışmanlarımız</Link>            
          <Link className='link' to="/news" onClick={showNavbar}>Haberler</Link>
          <Link className='link' to="/achievements" onClick={showNavbar}>Başarılarımız</Link>          
          <Link className='link' to="contact" onClick={showNavbar}>İletişim</Link>
          <button className='nav-btn nav-close-btn' onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className='nav-btn' onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
  )
}

export default Navbar