import { Routes, Route } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Homepage from './views/Homepage';
import Services from './views/ServicesPage';
import About from './views/About';
import ArGeService from './views/Ar-GeService';
import KosgebService from './views/KosgebService';
import TkdkService from './views/TkdkService';
import SoftwareService from './views/SoftwareService';
import SgkService from './views/SgkService';
import EngineeringService from './views/EngineeringService';
import EnvironmentalService from './views/EnvironmentalService';
import KobiService from './views/KobiService';
import TeknofestService from './views/TeknofestService';
import TubitakService from './views/TubitakService';
import Contact from './views/Contact';
import News from './views/News';
import Achievements from './views/Achievements';
import Consultant from './views/Consultant';

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/arge" element={<ArGeService />} />
        <Route path="/kosgeb" element={<KosgebService />} />
        <Route path="/kobi" element={<KobiService />} />
        <Route path="/tkdk" element={<TkdkService />} />
        <Route path="/software" element={<SoftwareService />} />
        <Route path="/sgk" element={<SgkService />} />
        <Route path="/engineering" element={<EngineeringService />} />
        <Route path="/environmental" element={<EnvironmentalService />} />
        <Route path="/teknofest" element={<TeknofestService />} />
        <Route path="/tubitak" element={<TubitakService />} />
        <Route path="/news" element={<News />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/consultants" element={<Consultant />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
