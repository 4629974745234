import React from 'react'
import PieChart from '../components/PieChart';
import LineChart from '../components/LineChart';
import '../styles/achievements.css';

function Achievements() {
  return (
    <div className='container-fluid bg-black'>
      <div className='chart-box1'>
        <LineChart />
      </div>
      <div className='chart-box1'>
        <PieChart />
      </div>          
                  
    </div>
  )
}

export default Achievements