import Logo from '../images/Kobimer_Logo_1.png';
import '../styles/footer.css';
import { BsTelephone, BsInstagram, BsFacebook, BsYoutube } from 'react-icons/bs';
import { GoMail } from 'react-icons/go';
import { SlLocationPin } from 'react-icons/sl';

function Footer() {
    return (
        <>
            <main className='container-fluid g-0'>
                <footer className="container-fluid bg-black text-white">
                    <div className='container py-5 px-5'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <img className='footer_logo' src={Logo} alt='Logo'></img>
                                <p className='py-3'>İnovasyonunuzu Destekliyoruz, Geleceği Birlikte İnşa Ediyoruz.</p>
                            </div>
                            <div className='col-md-8'>
                                {/*<div className='d-flex justify-content-around'>
                                    <div>
                                        <h5 className={styles.footer_list_heading}>Navigation</h5>
                                        <ul className={styles.footer_list}>
                                            <a href='/'><li className='mb-1'>Yardım</li></a>
                                            <a href='/'><li className='mb-1'>İletişim</li></a>
                                            <a href='/'><li className='mb-1'>SSS</li></a>
                                            <a href='/'><li className='mb-1'>Gizlilik</li></a>
                                        </ul>
                                    </div>
                                    <div>
                                        <h5 className={styles.footer_list_heading}>Navigation</h5>
                                        <ul className={styles.footer_list}>
                                            <a href='/'><li className='mb-1'>Yardım</li></a>
                                            <a href='/'><li className='mb-1'>İletişim</li></a>
                                            <a href='/'><li className='mb-1'>SSS</li></a>
                                            <a href='/'><li className='mb-1'>Gizlilik</li></a>
                                        </ul>
                                    </div>                                    
                                </div>*/}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-9'>
                                <div className='row py-3'>
                                    <div className='col-lg-4 d-flex align-items-center justify-content-center'>
                                        <div className='footer_icon'><BsTelephone /></div>
                                        <div><p className='footer_icon_text'>+(90) 532 260 42 00</p></div>
                                    </div>
                                    <div className='col-lg-4 d-flex align-items-center justify-content-center'>
                                        <div className='footer_icon'><GoMail /></div>
                                        <div><p className='footer_icon_text'>info@kobimer.net</p></div>
                                    </div>
                                    <div className='col-lg-4 d-flex align-items-center justify-content-center'>
                                        <div className='footer_icon'><SlLocationPin /></div>
                                        <div><p className='footer_icon_text'>Cappadocia, TR</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-3 d-flex align-items-center justify-content-center'>
                                <div className='footer_socialmedia_icon_wrapper'>
                                    <div className='footer_socialmedia_icon_box'><a className='footer_socialmedia_icon_link' href='https://www.instagram.com/woswosmedya/' target="_blank" rel='noreferrer'><BsInstagram /></a></div>
                                    <div className='footer_socialmedia_icon_box'><a className='footer_socialmedia_icon_link' href='https://www.instagram.com/woswosmedya/' target="_blank" rel='noreferrer'><BsYoutube /></a></div>
                                    <div className='footer_socialmedia_icon_box'><a className='footer_socialmedia_icon_link' href='https://www.instagram.com/woswosmedya/' target="_blank" rel='noreferrer'><BsFacebook /></a></div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='text-center'>
                            &copy; 2023 Kobimer Ar-Ge Danışmanlık • Tüm Hakları Saklıdır
                        </div>
                    </div>
                </footer>
            </main>
        </>
    )
}

export default Footer;