import React from 'react'
import '../styles/consultant.css';
import eray from '../images/eray.jpg';
import mutlu from '../images/mutlu.jpg';
import random from '../images/person.jpg';
import agah from '../images/agah.jpeg';
import furkan from '../images/furkan.jpeg';

function Consultant() {
  return (
    <div className='container-fluid bg-black text-white'>
        <div className='container'>
            <h2 className='consultant-title'>Danışmanlarımız</h2>
            <div className='consultant-grid-container'>
                <div className='consultant-grid-item'>
                    <div className='consultant-img-wrapper'><img className='consultant-img' src={eray} alt='consultant'></img></div>
                    <p>Eray Sakarya</p>
                </div>
                <div className='consultant-grid-item'>
                    <div className='consultant-img-wrapper'><img className='consultant-img' src={agah} alt='consultant'></img></div>
                    <p>Agah Bahadır Ceylan</p>
                </div>
                <div className='consultant-grid-item'>
                    <div className='consultant-img-wrapper'><img className='consultant-img' src={mutlu} alt='consultant'></img></div>
                    <p>Mutlu Öğütgen</p>
                </div>
                <div className='consultant-grid-item'>
                    <div className='consultant-img-wrapper'><img className='consultant-img' src={furkan} alt='consultant'></img></div>
                    <p>Furkan Öğütgen</p>
                </div>
                <div className='consultant-grid-item'>
                    <div className='consultant-img-wrapper'><img className='consultant-img' src={random} alt='consultant'></img></div>
                    <p>Mehmet Ünal</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Consultant