import React from 'react'
import AboutHero from '../components/AboutHero'

function About() {
  return (
    <>
    <AboutHero/>
    </>
  )
}

export default About