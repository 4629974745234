import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/servicespage.css';
import arge from '../images/arge2.svg';
import teknofest from '../images/teknofest.svg';
import kosgeb from '../images/kosgeb.svg';
import kobi from '../images/kobi.svg';
import tkdk from '../images/tkdk.svg';
import software from '../images/software.svg';
import sgk from '../images/sgk.svg';
import engineering from '../images/engineer.svg';
import environmental from '../images/environmental.svg';
import construction from '../images/construction.svg';
import tubitak from '../images/tubitak.svg';


function Services() {
  return (
    <div className='container-fluid bg-black text-white'>
      <div className='container'>
        <h2 className='service-title-page'>Hizmetlerimiz</h2>
        <div className='container-grid'>
          <Link className='service-link' to='/arge' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={arge} alt='svg'></img></div>            
              <h5>Ar-Ge Danışmanlığı</h5>
            </div>
          </Link>
          <Link className='service-link' to='/tubitak' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={tubitak} alt='svg'></img></div>            
              <h5>TÜBİTAK Danışmanlığı</h5>
            </div>
          </Link>
          <Link className='service-link' to='/teknofest' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={teknofest} alt='svg'></img></div>            
              <h5>Teknofest Danışmanlığı</h5>
            </div>
          </Link>
          <Link className='service-link' to='/kosgeb' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={kosgeb} alt='svg'></img></div>            
              <h5>KOSGEB Danışmanlığı</h5>
            </div> 
          </Link>
          <Link className='service-link' to='/kobi' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={kobi} alt='svg'></img></div>            
              <h5>KOBİ Danışmanlığı</h5>
            </div>
          </Link>         
          <Link className='service-link' to='/tkdk' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={tkdk} alt='svg'></img></div>            
              <h5>TKDK Danışmanlığı</h5>
            </div>
          </Link>
          <Link className='service-link' to='/software' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={software} alt='svg'></img></div>            
              <h5>Yazılım Danışmanlığı</h5>
            </div> 
          </Link>
          <Link className='service-link' to='/sgk' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={sgk} alt='svg'></img></div>            
              <h5>SGK Danışmanlığı</h5>
            </div>
          </Link>         
          <Link className='service-link' to='/engineering' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={engineering} alt='svg'></img></div>            
              <h5>Mühendislik Danışmanlığı</h5>
            </div>
          </Link>
          <Link className='service-link' to='/environmental' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={environmental} alt='svg'></img></div>            
              <h5>Çevre Danışmanlığı</h5>
            </div>
          </Link>                    
          <Link className='service-link' to='/' onClick={() => window.scrollTo(0, 0)}>
            <div className='grid-item'>
              <div className='grid-img-wrapper'><img className='grid-img' src={construction} alt='svg'></img></div>            
              <h5>İnşaat Danışmanlığı</h5>
            </div>
          </Link>                    
        </div>
      </div>
    </div>
  )
}

export default Services