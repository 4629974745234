import React from 'react'
import '../styles/argeservice.css';
import software from '../images/software.svg';

function SoftwareService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={software} alt='software'></img></div>
        <h1 className='text-bold'>Yazılım Danışmanlığı</h1>
        <h5>Yazılım Danışmanlığı: İşletmenizi Dijital Dönüşüme Taşıyan Anahtar</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>İşletmeniz için uygun yazılım çözümlerini bulmak ve bu çözümleri etkin bir şekilde kullanmak, rekabet avantajı sağlamanın ve verimliliği artırmanın kilit noktalarından biridir.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>İhtiyaç Analizi ve Öneri:</span><span> İşletmenizin ihtiyaçlarına özel olarak detaylı bir analiz gerçekleştiriyor ve mevcut yazılım altyapınızı değerlendiriyoruz. Ardından, ihtiyaçlarınıza en uygun yazılım çözümlerini öneriyoruz.</span></li>
          <li><span className='text-bold'>Yazılım Seçimi ve Entegrasyon:</span><span> İşletmeniz için en uygun yazılım çözümlerini belirleyip, bu çözümleri başarılı bir şekilde entegre ediyoruz. Mevcut sistemlerinizle uyumlu, verimli ve etkili bir entegrasyon sağlıyoruz.</span></li>
          <li><span className='text-bold'>Özelleştirilmiş Yazılım Geliştirme:</span><span> İşletmenizin özel ihtiyaçlarına yönelik özelleştirilmiş yazılım geliştirme hizmetleri sunuyoruz. Bu, iş süreçlerinizi optimize etmek ve daha etkili bir şekilde yönetmek için özel çözümler anlamına gelir.</span></li>
          <li><span className='text-bold'>Eğitim ve Destek:</span><span> Yeni yazılım çözümlerine geçiş aşamasında eğitim ve destek sağlıyoruz. Çalışanlarınızın yazılımı etkin bir şekilde kullanabilmesi için gerekli eğitimleri düzenliyor ve sürekli destek sunuyoruz.</span></li>
          <li><span className='text-bold'>Güvenlik ve Veri Koruma:</span><span> Yazılım güvenliği ve veri koruma konularında uzman ekibimiz, işletmenizin verilerini güven altında tutmanızı sağlar. Gelişmiş güvenlik çözümleri ile iş sürekliliğinizi ve güvenilirliğinizi artırırız.</span></li>
        </ul>
        <p className='padding-y'>Yazılım danışmanlığı hizmetlerimizle, işletmenizi dijital dönüşüme taşımanın yanı sıra, operasyonel verimliliği artırmanıza ve müşteri deneyimini iyileştirmenize yardımcı oluyoruz. Profesyonel ekibimizle birlikte, geleceğin iş dünyasına hazırlanmanız için yanınızdayız.</p>
      </div>
    </div>
  )
}

export default SoftwareService