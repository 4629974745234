import React from 'react'
import '../styles/argeservice.css';
import tubitak from '../images/tubitak.svg';

function TubitakService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={tubitak} alt='kobi'></img></div>
        <h1 className='text-bold'>TÜBİTAK Danışmanlığı</h1>
        <h5>TÜBİTAK Danışmanlığı: İnovasyon ve Teknolojiye Yolculuk</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Teknolojinin hızla evrildiği günümüzde, firmaların rekabet avantajını korumak ve sürdürmek için inovasyon ve teknolojiye odaklanmaları kaçınılmazdır. Türkiye Bilimsel ve Teknolojik Araştırma Kurumu, bu hedeflere ulaşmak isteyen işletmelere sağladığı danışmanlık hizmetleriyle öne çıkmaktadır.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Araştırma ve Geliştirme Projeleri:</span><span> TÜBİTAK danışmanlığı, müşterilere özel Ar-Ge projelerinin tasarımından uygulamasına kadar geniş bir yelpazede destek sunar. Projelerin finansmanı, takibi ve değerlendirmesi konularında uzman ekipler tarafından rehberlik sağlanır.</span></li>
          <li><span className='text-bold'>İnovasyon Stratejileri:</span><span> İnovasyon, işletmelerin sadece bugününü değil, aynı zamanda geleceğini de şekillendirir. TÜBİTAK danışmanları, müşterilere özgü iş ihtiyaçlarına uygun inovasyon stratejileri geliştirir ve uygular.</span></li>
          <li><span className='text-bold'>Teknoloji Transferi:</span><span> Yenilikçi çözümlerin ticarileştirilmesi ve pazarda değer bulması için TÜBİTAK, teknoloji transferi konusunda sağlam bir yol haritası sunar. Patentleme, lisanslama ve endüstri ile iş birlikleri gibi konularda danışmanlık hizmetleri verilir.</span></li>
          <li><span className='text-bold'>Araştırma Altyapısı:</span><span> TÜBİTAK, müşterilere en son teknolojiye sahip araştırma altyapılarına erişim sağlama konusunda destek olur. Laboratuvarlar, test tesisleri ve veri analitiği gibi altyapılar, işletmelerin inovasyon süreçlerini hızlandırır.</span></li>
          <li><span className='text-bold'>Eğitim ve Kapasite Geliştirme:</span><span> TÜBİTAK danışmanlığı, işletme içindeki ekiplerin teknik yetkinliklerini artırmak için özel eğitim programları düzenler. Bu sayede, şirket personeli en yeni teknoloji ve yöntemlerle tanışarak iş süreçlerini optimize eder.</span></li>
        </ul>
        <p className='padding-y'>TÜBİTAK danışmanlığı ile işletmeler, rekabetçi bir avantaj elde etmek ve geleceğin teknolojik dönüşümlerine liderlik etmek için güçlü bir ortaklık kurarlar. Bilgi, tecrübe ve inovasyonun birleştiği bu yolculuk, işletmeleri yeni başarı hikayelerine taşır.</p>
      </div>
    </div>
  )
}

export default TubitakService