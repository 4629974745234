import React from 'react';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Brand from '../components/Brand';

function Homepage() {
  return (
    <>
        <Hero />
        <Services />
        <Brand />
    </>
  )
}

export default Homepage