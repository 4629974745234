import React from 'react'
import '../styles/argeservice.css';
import teknofest from '../images/teknofest.svg';

function TeknofestService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={teknofest} alt='teknofest'></img></div>
        <h1 className='text-bold'>Teknofest Danışmanlığı</h1>
        <h5>Teknofest Danışmanlığı: Geleceğe Yön Veren Projelerde Yanınızdayız!</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Teknofest, gençlerin teknoloji ve yenilik alanında yeteneklerini sergiledikleri, rekabet ettikleri ve öğrendikleri bir platform olarak Türkiye'nin en büyük teknoloji ve havacılık etkinliğidir. Projelerinizi bu önemli platformda öne çıkarmak ve başarı elde etmek için Teknofest Danışmanlığı hizmetimizle yanınızdayız.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Proje Analizi ve Stratejisi:</span><span> Teknofest'e hazırlık sürecinde projenizin detaylı bir analizini yapıyoruz. Güçlü yönlerinizi belirleyerek, proje stratejinizi oluşturuyoruz. Hangi kategoride yer alırsanız alın, projenizi en iyi şekilde yönetmeniz için size rehberlik ediyoruz.</span></li>
          <li><span className='text-bold'>Teknik Destek ve Uzmanlık:</span><span> Projelerinizi teknik açıdan değerlendiriyor, geliştirme sürecinizde karşılaşabileceğiniz zorluklara çözümler üretiyoruz. Alanında uzman ekibimiz, teknik detaylarınızı anlamak ve en iyi sonuçları elde etmek için sizinle birlikte çalışıyor.</span></li>
          <li><span className='text-bold'>Ekip Oluşturma ve Eğitim:</span><span> Başarılı projelerin temelinde güçlü ekipler bulunur. Size uygun bir ekip oluşturmanız için rehberlik ediyor ve ekip üyelerinizi projenizin gereksinimlerine uygun şekilde eğitiyoruz.</span></li>
          <li><span className='text-bold'>Finansal Planlama ve Raporlama:</span><span> Projelerinizi finansal açıdan yönetmek, başarıya ulaşmanın önemli bir adımıdır. Sizin için uygun bir finansal plan oluşturuyor, bütçenizi etkili bir şekilde yönetmenize yardımcı oluyoruz.</span></li>
          <li><span className='text-bold'>Sunum Teknikleri ve İletişim Stratejileri:</span><span> Projelerinizi jüriye başarılı bir şekilde sunmak için etkili sunum teknikleri ve iletişim stratejileri geliştiriyoruz. Projenizi en iyi şekilde ifade etmeniz için size destek sağlıyoruz.</span></li>
        </ul>
        <p className='padding-y'>Teknofest yolculuğunuzda projelerinizi güçlendirmek, potansiyelinizi en üst düzeye çıkarmak ve geleceğe yön vermek için Teknofest Danışmanlığı ekibimize danışın. Geleceğin teknoloji liderleri arasında yer almak için birlikte çalışalım.</p>
      </div>
    </div>
  )
}

export default TeknofestService