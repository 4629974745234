import React from 'react'
import '../styles/argeservice.css';
import environmental from '../images/environmental.svg';

function EnvironmentalService() {
  return (
    <div className='container-fluid bg-black'>
      <div className='container text-center service-wrapper'>
        <div className='service-img'><img src={environmental} alt='environmental'></img></div>
        <h1 className='text-bold'>Çevre Danışmanlığı</h1>
        <h5>Çevre Danışmanlığı: Doğa Dostu Geleceğe Adım Atın!</h5>
      </div>
      <div className='container service-wrapper'>
        <p className='padding-y'>Doğanın korunması ve sürdürülebilir bir gelecek için çevre danışmanlığı hizmetlerimizle işletmenizin çevresel performansını artırıyoruz.</p>
        <h3 className='text-bold'>Hizmetlerimiz:</h3>
        <ul>
          <li><span className='text-bold'>Çevresel Performans Değerlendirmesi:</span><span> İlk adım olarak işletmenizin mevcut çevresel etkilerini belirliyor, enerji tüketimi, atık yönetimi ve karbon ayak izi gibi faktörleri değerlendirerek detaylı bir çevresel performans raporu oluşturuyoruz.</span></li>
          <li><span className='text-bold'>Yasal Uyum ve Lisanslama:</span><span> Çevre mevzuatına uyum sağlamak ve gerekli lisanslama süreçlerini yönetmek adına uzman kadromuzla size rehberlik ediyoruz. Yasal gereklilikleri eksiksiz yerine getirerek işletmenizi risklerden koruyoruz.</span></li>
          <li><span className='text-bold'>Atık Yönetimi ve Geri Dönüşüm:</span><span> Atık yönetimi stratejileri oluşturarak işletmenizin atık üretimini azaltıyor ve geri dönüşüm projeleri ile çevreye olan etkinizi artırıyoruz. Sürdürülebilir atık yönetimi için pratik çözümler sunuyoruz.</span></li>
          <li><span className='text-bold'>Enerji Verimliliği ve Su Yönetimi:</span><span> Enerji tüketimini optimize ederek maliyetleri düşürüyor, su yönetimi stratejileri geliştirerek su tasarrufunu sağlıyoruz. Çevre dostu teknolojiler ve uygulamalarla işletmenizin kaynak kullanımını etkili bir şekilde yönetiyoruz.</span></li>
          <li><span className='text-bold'>Çevre Eğitim ve Farkındalık Programları:</span><span> Çevre dostu davranışların yaygınlaştırılması için eğitim programları düzenliyor, personeliniz ve paydaşlarınız arasında çevresel farkındalığı artırıyoruz.</span></li>
          <li><span className='text-bold'>Ekosistem Restorasyonu ve Doğal Alan Yönetimi:</span><span> Çevresel projeler kapsamında ekosistem restorasyonu ve doğal alan yönetimi çalışmaları gerçekleştirerek doğanın korunmasına katkıda bulunuyoruz.</span></li>
          <li><span className='text-bold'>Sürdürülebilirlik Stratejileri ve Raporlama:</span><span> Sürdürülebilirlik hedefleri belirleyerek işletmenizin uzun vadeli çevresel etkisini azaltıyor, çevresel performans raporları ile bu süreci şeffaf bir şekilde iletiyoruz.</span></li>
        </ul>
        <p className='padding-y'>Çevre danışmanlığı hizmetlerimizle işletmenizi çevre dostu, sürdürülebilir bir geleceğe taşımak için yanınızdayız. Doğayı korumak ve işletmenizi çevresel açıdan optimize etmek için bizimle iletişime geçin!</p>
      </div>
    </div>
  )
}

export default EnvironmentalService